<template>
  <ul class="nav">
    <li
      v-for="(link, index) in links"
      :key="index"
      :class="{
        current: $route.name === Object.keys(link)[0],
        active: checkStatus(index, getData())
      }"
    >
      <router-link
        v-if="checkStatus(index, getData())"
        :to="{ name: Object.keys(link)[0] }"
        ><icon
          class="icon"
          id="wrench"
          v-if="$route.name === Object.keys(link)[0]"
        /><icon class="icon" id="check" v-else />
        {{ Object.values(link)[0] }}</router-link
      >
      <span class="next" v-else
        >{{ index + 1 }}. {{ Object.values(link)[0] }}</span
      >
    </li>
  </ul>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      links: [
        { format: this.$t("format.title") },
        { styling: this.$t("design.title") },
        { time: this.$t("time.title") },
        { applicants: this.$t("applicants.title") },
        { settings: this.$t("settings.title") },
        { form: this.$t("form.title") },
        { survey: this.$t("survey.title") },
        { preview: this.$t("default.preview.title") }
      ]
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getData() {
      return this.$store.state.business.assistent;
    },
    checkStatus(index, data) {
      if (!data.type && index > 0) {
        return false;
      }

      if (!data.title && index > 1) {
        return false;
      }

      if (!data.form.length && index > 5) {
        return false;
      }

      if (!data.survey && index > 6) {
        return false;
      }

      if (data.type === "datepicker") {
        return this.checkStatusDatepicker(index, data);
      }

      return true;
    },
    checkStatusDatepicker(index, data) {
      if (!Object.keys(data.include.weekdays).length && index > 2) {
        return false;
      }
      return true;
    }
  }
};
</script>
<style lang="sass" scoped></style>
